<template>
  <div class="ercode">
    <div v-show="showcopy" class="copy">address copied</div>
    <canvas width="130px" height="130px" ref="mycan"></canvas>
    <div class="qrd">
      <img @click="handlechange" class="brsw" :src="token == 'bsc' ? bsc : eth" />
    </div>
    <div @click="copyadr()" class="flex">
      <div class="adr">
        {{ address }}
      </div>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.89185 4.42969H1.89262H9.02037C9.30717 4.42969 9.538 4.66115 9.538 4.94731V12.0751C9.538 12.3615 9.30598 12.5936 9.0195 12.5936H1.8935C1.60702 12.5936 1.375 12.3615 1.375 12.0751V4.94731C1.375 4.65953 1.6064 4.42924 1.89185 4.42969Z" fill="#1C70BC" stroke="#1C70BC"/>
<path d="M12.075 0.875H4.94637C4.38462 0.875 3.92874 1.33088 3.92874 1.8935V2.91112H10.0371C10.5997 2.91112 11.0556 3.367 11.0556 3.92963V11.0565H12.0732C12.6359 11.0565 13.0917 10.6006 13.0917 10.038V1.8935C13.0917 1.33088 12.6359 0.875 12.0732 0.875" fill="#1C70BC"/>
</svg>

    </div>
  </div>
</template>
<script>
import QRCode from "qrcode";
import tk1 from "./img/tk1.svg";
import tk2 from "./img/tk2.svg";
export default {
  props: ["address", "token"],
  emit:["change"],
  data() {
    return {
      eth: tk1,
      bsc: tk2,
      showcopy:false
    };
  },
  updated() {
    const can = this.$refs.mycan;
    if (this.address && can) {
      QRCode.toCanvas(can, this.address, { margin: 0, width: 130 }, (error) => {
        if (error) {
          console.log(error);
        }
      });
    }
  },
  mounted() {
    const can = this.$refs.mycan;
    if (this.address && can) {
      QRCode.toCanvas(can, this.address, { margin: 0, width: 130 }, (error) => {
        if (error) {
          console.log(error);
        }
      });
    }
  },
  methods:{
    handlechange() {
      this.$emit("change");
    },
    copyadr() {
      if(this.showcopy) return;
      this.showcopy = true;
      navigator.clipboard.writeText(this.address);
      setTimeout(()=>{
        this.showcopy = false;
      },800)
    }
  }
};
</script>
<style lang="less" scoped>
.ercode {
  padding: 20px;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  .qrd{
    padding-top: 10px;
  }
  .brsw{
    cursor: pointer;
  }
  .copy{
    position:absolute;
    bottom: 70px;
    text-align: center;
    z-index: 999;
    background-color: #f1f1f1;
    box-shadow: 0px 6px 20px rgba(20, 81, 138, 0.1);
    padding: 5px 10px;
    left:40%;
    border-radius: 6px;
  }
  .flex{
    display: flex;
    margin-top: 20px;
    background: #EFF6FF;
    border-radius: 2px;   
    padding: 4px;
    cursor: pointer;
    justify-content: space-evenly;
  }
  .adr {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    color: #4f4f4f;
  }
  canvas {
    width: 130px;
  }
}
</style>