<template>
  <div class="home">
    <div class="p1">
      <div class="p1box">
        <div class="title" :style="{fontSize: $i18n.locale === 'zh-cn' ? '66px' : '72px', lineHeight: $i18n.locale === 'zh-cn' ? '70px' : '65px'}">
          {{$t('Home.Banner._1')}}
        </div>
        <div class="subtitle">
          {{$t('Home.Banner._2')}}
        </div>
        <div class="subscribe-text">
          {{$t('Home.Banner._4')}}
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input v-model="ruleForm.name" :placeholder="$t('Home.Banner._8')"></el-input>
            <el-button  @click="submitForm('ruleForm')">{{$t('Home.Banner._5')}}</el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="button">
          <router-link
            class="read"
            target="_blank"
            to="/Roxe Chain White Paper.pdf"
            >{{$t('Home.Banner._3')}}</router-link
          >
        </div> -->
      </div>
    </div>
    <!--<HbtcAndBkexData />-->
    <Bridge />
    <div class="p2">
      <div>
        <img src="../../assets/img/p2-left.png" />
      </div>
      <div class="introduce">
        <div class="title">
          {{ $t('Home.Row_1._2') }}
          <!-- The next-generation blockchain purpose-built for payments. -->
        </div>
        <div class="text">
          {{ $t('Home.Row_1._3') }}
          <!-- Our vision is to enable the world to move value faster and cheaper,
          anywhere in the world. -->
        </div>
        <div class="subtitle">{{ $t('Home.Row_1._4') }}</div>
        <div class="text">
          {{ $t('Home.Row_1._5') }}
        </div>
        <div class="subtitle">{{ $t('Home.Row_1._6') }}</div>
        <div class="text">
          {{ $t('Home.Row_1._7') }}
        </div>
      </div>
    </div>
    <div class="p3">
      <div class="p3-box">
        <div class="title">{{ $t('Home.Row_2._1') }}</div>
        <div class="list">
          <div class="item">
            <img src="../../assets/img/p31.png" />
            <div class="title">{{ $t('Home.Row_2._2') }}</div>
            <div class="text">
             {{ $t('Home.Row_2._3') }}
            </div>
          </div>

          <div class="item">
            <img src="../../assets/img/p32.png" />
            <div class="title">{{ $t('Home.Row_2._4') }}</div>
            <div class="text">
              {{ $t('Home.Row_2._5') }}
            </div>
          </div>

          <div class="item">
            <img src="../../assets/img/p33.png" />
            <div class="title">{{ $t('Home.Row_2._6') }}</div>
            <div class="text">
              {{ $t('Home.Row_2._7') }}
            </div>
          </div>

          <div class="item">
            <img src="../../assets/img/p34.png" />
            <div class="title">{{ $t('Home.Row_2._8') }}</div>
            <div class="text">
              {{ $t('Home.Row_2._9') }}
            </div>
          </div>

          <div class="item">
            <img src="../../assets/img/p35.png" />
            <div class="title">{{ $t('Home.Row_2._10') }}</div>
            <div class="text">
              {{ $t('Home.Row_2._11') }}
            </div>
          </div>

          <div class="item">
            <img src="../../assets/img/p36.png" />
            <div class="title">{{ $t('Home.Row_2._12') }}</div>
            <div class="text">
              {{ $t('Home.Row_2._13') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p4">
      <div class="left">
        <div class="left-box">
          <div class="title">{{$t('Home.Row_3._1')}}</div>
          <p class="text">
            {{$t('Home.Row_3._2')}}
          </p>
          <p class="text">
            {{$t('Home.Row_3._3')}}
          </p>
          <p class="text">
            {{$t('Home.Row_3._4')}}
          </p>
          <a class="become" @click="open">{{$t('Home.Row_3._5')}}</a>
        </div>
      </div>
      <div class="right">
        <div class="title">{{$t('Home.Row_3._6')}}</div>
        <div class="text">
          <img src="../../assets/img/p41.png" />
          <p>{{$t('Home.Row_3._7')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png" />
          <p>{{$t('Home.Row_3._8')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png" />
          <p>{{$t('Home.Row_3._9')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png" />
          <p>{{$t('Home.Row_3._10')}}</p>
        </div>
        <div class="text">
          <img src="../../assets/img/p41.png" />
          <p>{{$t('Home.Row_3._11')}}</p>
        </div>
      </div>
    </div>
    <div class="p5">
      <div class="line"></div>
      <div class="title">{{$t('Home.Row_4._1')}}</div>
      <div class="list">
        <a href="https://ecsfin.com/" target="_blank"
          ><img src="../../assets/img/p51.svg"
        /></a>
        <a href="https://www.mercurity.finance/" target="_blank"
          ><img src="../../assets/img/p52.svg"
        /></a>
        <a href="https://www.apifiny.com/" target="_blank"
          ><img src="../../assets/img/p53.svg"
        /></a>
        <a href="https://www.zero2first.com/" target="_blank"
          ><img src="../../assets/img/p54.svg"
        /></a>
        <a href="https://www.daotrust.io/" target="_blank"
          ><img src="../../assets/img/daotrust.svg"  style="width: 160px;display: inline-block"
        /></a>
      </div>
    </div>
    <div class="p6">
      <div class="p6-box">
        <div class="list">
          <div class="item">
            <div class="title">{{$t('Home.Row_5._1')}}</div>
            <div class="text">
              {{$t('Home.Row_5._2')}}
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('Home.Row_5._3')}}</div>
            <div class="text">
              {{$t('Home.Row_5._4')}}
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('Home.Row_5._5')}}</div>
            <div class="text">
              {{$t('Home.Row_5._6')}}
            </div>
          </div>
        </div>
        <div class="become">
          <a @click="open">{{$t('Home.Row_5._7')}}</a>
        </div>
      </div>
    </div>

    <div class="p7">
      <div class="title">{{$t('Home.Row_6._1')}}</div>
      <div class="link">
        <a href="https://twitter.com/RoxeChain" target="_blank"><img src="../../components/img/twitter.svg"/></a>
        <!-- <img src="../../components/img/facebook.svg"/> -->
        <a href="https://t.me/roxechainglobal" target="_blank"><img src="../../components/img/telegram.svg"/></a>
        <a href="https://medium.com/roxechain" target="_blank"><img src="../../components/img/m.svg"/></a>
        <a href="https://discord.gg/d3mDxd6th7" target="_blank"><img src="../../components/img/Union.svg"/></a>
      </div>
    </div>
    <Supernodes ref="supernodes"></Supernodes>

    <el-dialog
      :visible.sync="robotModalflag"
      :close-on-click-modal="false"
      width="350px"
      :modal="false"
    >
      <div id="roxeSendEmail"></div>
      <p slot="footer"></p>
    </el-dialog>
    <!-- </div> -->
    <!-- <roxe-footer></roxe-footer> -->
  </div>
</template>

<script>
// import RoxeFooter from "../../components/footer.vue";
import Supernodes from "../../components/applySupernode.vue";
import Swiper from 'swiper';
import "swiper/css/swiper.min.css";
import Axios from 'axios'
import Bridge from '../../components/cross/bridge.vue'
import { onloadCallback } from "../../../api/usersystem.js";
export default {
  data() {
    return {
      mainTitle: "Resend Verification Email",
      maxWidth_600: true,
      robotModalflag:false,

      swiperOptions: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable :true,
        }
      },
      slides: [
        {
          // img_url: require('../../assets/img/LinkedIn.png'),
          id: 1,
          imgList: [
            {
              img_url: require('./img/row_1.svg'),
              img_hover_url: require('./img/row_1_hover.svg'),
            },
            {
              img_url: require('./img/row_2.svg'),
              img_hover_url: require('./img/row_2_hover.svg'),
            },
            {
              img_url: require('./img/row_3.svg'),
              img_hover_url: require('./img/row_3_hover.svg'),
            },
            {
              img_url: require('./img/row_4.svg'),
              img_hover_url: require('./img/row_4_hover.svg'),
            },
            {
              img_url: require('./img/row_5.svg'),
              img_hover_url: require('./img/row_5_hover.svg'),
            },
            {
              img_url: require('./img/row_6.svg'),
              img_hover_url: require('./img/row_6_hover.svg'),
            },
            {
              img_url: require('./img/row_7.svg'),
              img_hover_url: require('./img/row_7_hover.svg'),
            },
            {
              img_url: require('./img/row_8.svg'),
              img_hover_url: require('./img/row_8_hover.svg'),
            }
          ]
        },
        {
          // img_url: require('../../assets/img/Facebook.png'),
          id: 2,
          imgList: [
            {
              img_url: require('./img1/1.svg'),
              img_hover_url: require('./img1/1_hover.svg'),
            },
            {
              img_url: require('./img1/2.svg'),
              img_hover_url: require('./img1/2_hover.svg'),
            },
            {
              img_url: require('./img1/3.svg'),
              img_hover_url: require('./img1/3_hover.svg'),
            },
            {
              img_url: require('./img1/4.svg'),
              img_hover_url: require('./img1/4_hover.svg'),
            },
            {
              img_url: require('./img1/5.svg'),
              img_hover_url: require('./img1/5_hover.svg'),
            },
            {
              img_url: require('./img1/6.svg'),
              img_hover_url: require('./img1/6_hover.svg'),
            },
            {
              img_url: require('./img1/7.svg'),
              img_hover_url: require('./img1/7_hover.svg'),
            },
            {
              img_url: require('./img1/8.svg'),
              img_hover_url: require('./img1/8_hover.svg'),
            }
          ]
        },
        {
          // img_url: require('../../assets/img/Facebook.png'),
          id: 3,
          imgList: [
            {
              img_url: require('./img2/1.svg'),
              img_hover_url: require('./img2/1_hover.svg'),
            },
          ]
        }
      ],
      ruleForm: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('Home.Banner._6'), trigger: 'x' },
          { type: 'email', message:  this.$t('Home.Banner._7'), trigger: 'x' }
        ],
      }
    }
  },
  components: {
    Supernodes,
    Bridge
  },
  computed: {
  },
  mounted() {
    new Swiper (this.$refs.slider, {
      loop: true,
      autoplay:true,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
          clickable: true,
      }
    })
  },
  methods: {
    open() {
      this.$refs.supernodes.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var _that = this;
          _that.robotModalflag = true;
          this.$nextTick(() => {
            onloadCallback(
              "roxeSendEmail",
              function(res) {
                console.log(res, 'res')
                if (res) {
                  // console.log(process.env.VUE_APP_TITLE + `/about-us/send-email`)
                  Axios.post(process.env.VUE_APP_TITLE + `/about-us/send-email`, {
                    formType: "ROXE_TECH_NEWSLETTER",
                    captchaType: "GOOGLE_WEB",
                    captchaCode: res,
                    data: {
                      email: _that.ruleForm.name,
                    }
                  })
                  .then(res => {
                    _that.robotModalflag = false;
                    _that.$message({
                      message: 'Success',
                      type: 'success'
                    });
                     _that.$refs[formName].resetFields();
                  })
                  .catch(err => {
                    _that.$message({
                      message: err.message || 'Fail',
                      type: 'error'
                    });
                    _that.robotModalflag = false;
                  })
                }
              });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  
};
</script>

<style lang="less" scoped>
/deep/ .swiper-pagination-bullet {
  border-radius: 0;
}
/deep/ .swiper-pagination-bullet-active {
  background: #085DA3;
}
// /deep/ 
/deep/ .p1 .el-form-item__content {
  width: 520px;
  position: relative;
  @media screen and (max-width: 767px){
    width: 100%;
  }
  input {
    height: 54px;
    width: 523px;
    border-radius: 0px;
    background: #f9f6f63d;
    border: none;
    color: #fff;
    padding-right: 200px;
    @media screen and (max-width: 767px){
      width: 100%;
      padding-right: 113px;
    }
  }
  button {
    position: absolute;
    width: 180px;
    height: 46px;
    background: #FFAE69;
    font-family: Louis George Cafe;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #4F4F4F;
    text-align: center;
    border-radius: 0px;
    border: none;
    top: 4px;
    right: 2px;
    @media screen and (max-width: 767px){
      width: 100px;
      padding: 0;
      right: 4px;
    }
  }
}
.home {
  .farming {
    margin-bottom: 30px;
    text-align: center;
    font-family: Louis George Cafe;
    font-size: 24px;
    color: #085da3;
    padding-top: 130px;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: #085DA3;
    display: block;
    text-decoration: underline;
    @media screen and(max-width: 1110px) {
        padding-top: 280px;
    }
    @media screen and(max-width: 767px) {
      padding-top: 380px;
    }
  }
  .p1 {
    // background-image: url("../../assets/img/p1bg.png");
    // background-repeat: no-repeat;
    height: 730px;
    background-size: cover;
    padding-top: 230px;
    // background-position: center bottom;
    background: linear-gradient(180deg, #085DA3 0%, #3A89CA 100%);
    .p1box {
      width: 1110px;
      margin: 0 auto;
      .title {
        width: 880px;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        color: #ffffff;
        margin-bottom: 30px;
        font-size: 72px;
        line-height: 65px;
      }
      .subtitle {
        font-family: Louis George Cafe;
        color: #f6f8fb;
        width: fit-content;
        height: 50px;
        margin-bottom: 30px;
        font-family: Louis George Cafe;
        font-size: 24px;
        line-height: 28px;
        border-bottom: 2px solid rgb(255 255 255 / 20%);
        padding-bottom: 30px;
      }
      .subscribe-text {
        font-family: Gilroy;
        font-style: normal;
        // font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: capitalize;
        color: #FFFFFF;
        opacity: 0.8;
      }
      .demo-ruleForm {
        margin-top: 24px;
      }
      .button {
        display: flex;
      }
      .read {
        width: 195px;
        height: 44px;
        background: #ffae69;
        // border: 1px solid #FFFFFF;
        font-weight: 600;
        box-sizing: border-box;
        line-height: 44px;
        display: inline-block;
        text-align: center;
        color: #4f4f4f;
        font-size: 16px;
        font-family: Louis George Cafe;
        &:hover {
          opacity: 0.8;
        }
      }
      .join {
        width: 187px;
        height: 44px;
        background: #4aacfd;
        border: 1px solid #4aacfd;
        margin-left: 20px;
        display: inline-block;
        text-align: center;
        line-height: 44px;
      }
    }
  }
  .p2 {
    width: 1110px;
    margin: 0 auto;
    padding: 110px 0px 136px 0px;
    display: flex;
    justify-content: space-between;
    img {
      width: 524px;
      height: 517px;
      display: block;
    }
    .introduce {
      width: 439px;
      margin-top: 63px;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        color: #085da3;
        margin-bottom: 30px;
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 19px;
        color: #4f4f4f;
        margin-bottom: 30px;
      }
      .subtitle {
        font-family: Louis George Cafe;
        font-size: 20px;
        line-height: 100.3%;
        color: #085da3;
        margin-bottom: 16px;
      }
    }
  }

  .p3 {
    background: #f6f8fb;
    padding-bottom: 180px;
    .p3-box {
      width: 1110px;
      margin: 0 auto;
      .title {
        text-align: center;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 35px;
        color: #085da3;
        padding-top: 64px;
        margin-bottom: 54px;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          max-width: 350px;
          height: 350px;
          padding: 37px 30px 0;
          background: #ffffff;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          margin-bottom: 30px;
          img {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;
          }
          .title {
            font-family: Louis George Cafe;
            font-size: 20px;
            line-height: 23px;
            color: #085da3;
            margin-bottom: 27px;
            padding-top: 0px;
            text-align: left;
          }
          .text {
            font-family: Louis George Cafe;
            font-size: 16px;
            line-height: 20px;
            color: #4f4f4f;
          }
        }
      }
    }
  }

  .p4 {
    display: flex;
    .left {
      width: 46%;
      background: #1c70bd;
      height: 460px;
      margin-top: -100px;
      .left-box {
        float: right;
        width: 446px;
        margin-right: 50px;
        .title {
          font-family: ParmaPetit;
          font-style: normal;
          font-weight: normal;
          font-size: 38px;
          line-height: 35px;
          color: #ffffff;
          padding-top: 70px;
          margin-bottom: 40px;
        }
        .text {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 19px;
          color: #f6f8fb;
          margin-bottom: 20px;
        }
        .join {
          width: 187px;
          height: 44px;
          background: #4aacfd;
          border: 1px solid #4aacfd;
          // margin-left: 20px;
          display: inline-block;
          text-align: center;
          line-height: 44px;
          color: #fff;
          margin-top: 20px;
        }
        .become {
          display: inline-block;
          width: 194px;
          height: 44px;
          line-height: 44px;
          background: #2f98ee;
          color: #ffffff;
          font-family: Louis George Cafe;
          font-size: 16px;
          text-align: center;
          margin: 0 auto;
          margin-top: 20px;
          cursor: pointer;
          a {
            cursor: pointer;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .right {
      padding-left: 65px;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        color: #085da3;
        margin-top: 95px;
        margin-bottom: 40px;
      }
      .text {
        display: flex;
        img {
          width: 30px;
          height: 30px;
          display: inline-block;
          margin-right: 20px;
        }
        p {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 160%;
          color: #4f4f4f;
          margin-bottom: 35px;
        }
      }
    }
  }

  .p5 {
    .line {
      // width: 114px;
      // height: 4px;
      // background: #E0E0E0;
      margin: 0 auto;
      margin-top: 140px;
      margin-bottom: 138px;
      // text-align: center;

      width: 1020px;
      height: 4px;
      background: linear-gradient(
        270deg,
        rgba(224, 224, 224, 0) 14.71%,
        rgba(224, 224, 224, 0.524761) 50.23%,
        rgba(224, 224, 224, 0) 89.46%
      );
    }
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085da3;
      margin-bottom: 80px;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 126px;
      a {
        margin-right: 70px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .p6 {
    background: #f6f8fb;
    padding-top: 96px;
    padding-bottom: 65px;
    .p6-box {
      width: 1110px;
      margin: 0 auto;
      .list {
        display: flex;
        justify-content: space-between;
        .item {
          width: 350px;
          height: 283px;
          padding: 45px 30px 48px;
          background: #ffffff;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          .title {
            font-family: Louis George Cafe;
            font-size: 20px;
            line-height: 23px;
            color: #085da3;
            margin-bottom: 27px;
          }
          .text {
            font-family: Louis George Cafe;
            font-size: 16px;
            line-height: 20px;
            color: #4f4f4f;
          }
        }
      }
      .become {
        width: 194px;
        height: 44px;
        line-height: 44px;
        background: #2f98ee;
        color: #ffffff;
        font-family: Louis George Cafe;
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        margin-top: 60px;
        a {
          cursor: pointer;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .p7 {
    background: #f6f8fb;
    padding-top: 96px;
    padding-bottom: 96px;
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085da3;
      // margin-top: 74px;
      margin-bottom: 53px;
    }
    .link {
      display: flex;
      justify-content: center;
      // margin-bottom: 110px;
      a {
        margin-right: 30px;
        &:last-child {
          margin-right: 0px;
        }
        img {
          display: inline-block;
          width: 26px;
          filter: contrast(0.5);
          &:hover {
            filter: contrast(0.9);
          }
        }
      }
    }
  }

  .p8 {
    padding-bottom: 96px;
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 35px;
      text-align: center;
      color: #085da3;
      margin-top: 74px;
      margin-bottom: 53px;
    }
    .swiper-container-android .swiper-slide, .swiper-wrapper {
      height: 255px;
    }
    .swiper-container {
      width: 1000px;
      height: 350px;
      .hide {
        display: none;
      }
      .swiper-slide {
        display: flex;
        // align-content: center;
        flex-wrap: wrap;
        align-content: space-between;
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:hover {
            cursor: pointer;
            .show {
              display: none;
            }
            .hide {
              display: block;
            }
          }
        }
      }
      .slide1 {
        .img0 {
          margin-right: 105px;
        }
        .img1 {
          margin-right: 120px;
        }
        .img2 {
          margin-right: 101px;
        }
        .img4 {
          margin-right: 111px;
        }
        .img5 {
          margin-right: 111px;
        }
        .img6 {
          margin-right: 107px;
        }
      }
      .slide2 {
        .img0 {
          margin-right: 70px;
          margin-top: 40px;
        }
        .img1 {
          margin-right: 80px;
          margin-top: 40px;
        }
        .img2 {
          margin-right: 90px;
          margin-top: 40px;
        }
        .img3 {
          margin-top: 40px;
        }
        .img4 {
          margin-right: 95px;
          margin-left: 30px;
        }
        .img5 {
          margin-right: 99px;
        }
        .img6 {
          margin-right: 80px;
        }
      }
      .slide3 {
        .img0 {
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1210px) {
  .home .p1 {
    padding-left: 50px;
    padding-right: 50px;
    background-size: cover;
    .p1box {
      width: auto;
      .title {
        width: auto;
        font-size: 40px!important;
        line-height: 40px!important;
      }
      .subtitle {
        width: auto;
        height: auto;
      }
      .button {
        flex-wrap: wrap;
        .read {
          margin-right: 20px;
          margin-bottom: 20px;
          &:hover {
            opacity: 0.8;
          }
        }
        .join {
          margin-left: 0px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .home .p2 {
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
    .introduce {
      width: auto;
    }
    img {
      width: 100%;
      height: auto;
      // height: auto;
    }
  }
  .home .p3 {
    .p3-box {
      padding-left: 50px;
      padding-right: 50px;
      width: auto;
      .list {
        .item {
          width: 100%;
          // min-width: 320px;
          max-width: none;
          height: auto;
          padding-bottom: 37px;
        }
      }
    }
  }
  .home .p4 {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    .left {
      width: auto;
      padding: 0 30px;
      height: auto;
      padding-bottom: 30px;
      .left-box {
        margin-right: 0px;
        width: auto;
      }
    }
    .right {
      padding-left: 0px;
    }
  }
  .home .p5 {
    .line {
      width: 60%;
    }
    .list {
      // flex-wrap: wrap;
      display: block;
      // justify-content: center;
      img {
        display: block!important;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }
  .home .p6 {
    padding-left: 50px;
    padding-right: 50px;
    .p6-box {
      width: auto;
      .list {
        flex-wrap: wrap;
        .item {
          width: auto;
          margin-bottom: 30px;
          height: auto;
        }
      }
    }
  }
  .home .p8 {
    .swiper-container {
      width: 80%;
      height: auto;
      padding-bottom: 80px;
    }
  }
  .home .p8 .swiper-container-android .swiper-slide, .home .p8 .swiper-wrapper {
    height: auto;
    .swiper-slide {
      display: block;
      // flex-wrap: wrap;
      // align-content: space-between;
      // justify-content: space-between;
      // align-items: center;
      span {
        margin-bottom: 40px;
        display:inherit;

      }
      img {
        margin: 0 auto!important;
        display: inherit;
        &.hide {
          display: none;
        }
        // margin-right: 60px!important;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1110px) {
  .p1-2 {
    padding-top: 200px;
  }
}


@media screen and(max-width: 767px) {
  .home .p1 {
    padding-top: 185px;
    height: 830px;
  }
}
</style>