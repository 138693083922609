<template>
  <div class="bridge">
    <div class="title">{{ $t("Home.Bridge.title") }}</div>
    <div class="t2"><img src="./img/icon.svg" /></div>
    <div class="body">
      <div class="info">
        <div class="data">
          <div class="item">
            <div>ETH - BSC {{ $t("Home.Bridge.balance") }}:</div>
            <span>{{ ethLimit }} ROC</span>
          </div>
          <div class="item">
            <div>BSC - ETH {{ $t("Home.Bridge.balance") }}:</div>
            <span>{{ bscLimit }} ROC</span>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="scroll">
          <div class="title">{{ $t("Home.Bridge.guide") }}</div>
          <div v-if="!zhcn">
            <div>1. Cross-chain quota:</div>
            <div class="m20">
              From October to December of 2021 - the bridge can support
              <span class="info1">100,000</span> ROC cross-chain transactions in
              total. 50,000 ROC quota from Ethereum to BSC and the remaining
              50,000 quota from BSC to Ethereum.
            </div>
            <div class="m20">
              January of 2021 and afterwards - the bridge can support
              <span class="info1">200,000</span> ROC cross-chain transactions in
              total. 100,000 ROC quota from Ethereum to BSC and the remaining
              100,000 quota from BSC to Ethereum.
            </div>
            <div>2. Quota reset:</div>
            <div class="m20">daily (UTC+0) at 0:00am.</div>
            <div>3. Cross-chain rules:</div>
            <div class="m20">
              Check the remaining quota before making the transaction.<br />
              No single transaction can exceed the limit of 50,000 ROC.<br />
              When the cross-chain quota is insufficient, the system will
              support automatic order splitting.
            </div>
            <ul>
              <li>
                Order Splitting Rule: User Order Amount > Cross-chain Quota +
                Cross-chain Service Fee*2
              </li>
              <li>
                The exceeding quota part will be pending for execution until the
                next day.
              </li>
              <li>
                Each split order will be charged a separate cross-chain service
                fee.
              </li>
            </ul>
            <div class="m20">
              <span class="info1">At the first minute (UTC+0) each day</span>,
              users can participate in the "1 minute quota snap-up" campaign.
              The daily quota allocation rule is shown as follows.
            </div>
            <ul>
              <li>
                Process the transactions occurred during the "1-minute quota
                snap-up".
              </li>
              <li>
                Then process the orders waiting to be executed the day before.
              </li>
              <li>And finally allocate other orders intraday.</li>
            </ul>
            <div>4. Cross-chain Service Fee:</div>
            <div>「ETH -> BSC」 5 ROC per transaction</div>
            <div class="m20">「BSC -> ETH」 40 ROC per transaction</div>
            <div>5. Guidance (Example: ETH->BSC):</div>
            <div>
              Transfer ROC from your<span class="info1"
                >Ethereum wallet (NOT YOUR EXCHANGE WALLET)</span
              >
              to the designated contract address. You can scan the above OR code
              to get the contract address.
            </div>
            <div>Submit the amount of ROC token for cross-chain.</div>
            <div>Switch to your BSC wallet and check your balance.</div>
          </div>
          <div v-if="zhcn">
            <div>1. 跨链额度:</div>
            <div class="m20">
              2021年10月~12月：跨链桥的额度上限为每日10万ROC。其中5万额度支持从以太坊跨链至BSC，剩余5万额度支持从BSC跨链至以太坊。
            </div>
            <div class="m20">
              2022年1月起：跨链桥的额度上限为每日<span class="info1">20</span
              >万ROC。双向分别10万。
            </div>
            <div>2. 额度刷新时间:</div>
            <div class="m20">每日0：00am (UTC+0) 刷新。</div>
            <div>3. 跨链规则:</div>
            <div class="m20">
              跨链前请先查询当日Bridge的剩余额度。<br />
              单笔跨链交易不能超过5万ROC。<br />
              当跨链额度不足时，合约支持自动拆单：
            </div>
            <ul>
              <li>拆单规则：订单金额 > 跨链剩余额度 + 手续费*2</li>
              <li>拆单后的剩余订单交易会在队列中等待，次日起继续执行。</li>
              <li>拆分后的每笔订单会单独收取手续费。</li>
            </ul>
            <div class="m20">
              每日0：00 (UTC+0) 的<span class="info1">第1分钟</span
              >开启“1分钟额度抢购”活动。当日额度分配规则为：先处理“1分钟额度抢购”活动的订单，再分配前一天等待执行的订单，最后再分配当日其他时间的订单。
            </div>
            <div>4. 跨链手续费:</div>
            <div>「ETH -> BSC」 每笔 5 ROC</div>
            <div class="m20">「BSC -> ETH」 每笔 40 ROC</div>
            <div>5.使用方法（ETH->BSC为例）:</div>
            <div>
              <span class="info1">将以太坊钱包中(请勿从交易所直接提币)</span
              >的ROC打入指定合约，合约地址可扫描上方二维码获得。
            </div>
            <div>输入您需要跨链的ROC数量，并提交。</div>
            <div>切换至您的BSC钱包，并查收。</div>
          </div>
        </div>
      </div>
      <div class="qrcode">
        <div v-if="false" class="tit flex">
          <span>{{ $t("Home.Bridge.address") }}</span>
          <div class="list flex">
            <div
              @click="swto('eth')"
              :class="['sp', token == 'eth' ? 'active' : '']"
            >
              ETH-BSC
            </div>
            <div
              @click="swto('bsc')"
              :class="['sp', token == 'bsc' ? 'active' : '']"
            >
              BSC-ETH
            </div>
          </div>
        </div>
        <div v-if="false" class="flex">
          <ECode v-if="address" @change="swto" :token="token" :address="address" />
        </div>
        <div class="sysmain">{{$t('sysmain')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import ECode from "./ercode.vue";
import i18n from "@/i18n";

export default {
  components: {
    ECode,
  },
  data() {
    return {
      limit: "",
      address: "",
      ethAddress: "",
      bscAddress: "",
      token: "",
      bscLimit: "0",
      ethLimit: "0",
    };
  },
  mounted() {
    fetch("/api/bsc-rss/system/roc-limit")
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.result) {
          this.limit = json.result.limit;
          this.ethAddress = json.result.ethAddress;
          this.bscAddress = json.result.bscAddress;
          this.ethLimit = json.result.ethLimit;
          this.bscLimit = json.result.bscLimit;
          if(this.token == '') {
            this.token = 'eth'
          }
          if (this.token == "eth") {
            this.address = this.ethAddress;
          } else {
            this.address = this.bscAddress;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    zhcn() {
      return this.$i18n.locale == "zh-cn";
    },
  },
  methods: {
    swto(tk) {
      if (tk) {
        this.token = tk;
      } else {
        this.token = this.token == "eth" ? "bsc" : "eth";
      }
      if (this.token == "eth") {
        this.address = this.ethAddress;
      } else {
        this.address = this.bscAddress;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bridge {
  padding-top: 150px;
  margin: 0 auto;
  max-width: 1110px;
  .sysmain{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 120px;
  }
  .panel {
    font-family: Louis George Cafe;
    font-size: 16px;
    line-height: 20px;
    color: #f6f8fb;
    padding-left: 30px;
    padding-top: 40px;
    overflow: hidden;
    padding-bottom: 20px;
    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 6px;
    }
    ::-webkit-scrollbar {
      background-color: #3988c9;
      width: 9px;
      border-radius: 6px;
    }
    .scroll {
      max-width: 530px;
      height: 100%;
      overflow-y: auto;
      ul {
        padding-left: 20px;
        li {
          list-style: disc;
        }
      }
      div {
        margin-bottom: 5px;
      }
      .m20 {
        margin-bottom: 16px;
      }
      .title {
        text-align: left;
        margin-bottom: 10px;
        font-size: 20px;
        color: #fafafa;
        line-height: 23px;
      }
    }
  }
  .t2 {
    text-align: center;
    margin-top: 30px;
  }
  .title {
    text-align: center;
    font-family: ParmaPetit;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 35px;
    color: #085da3;
  }
  .body {
    margin-top: 50px;
    position: relative;
    .info {
      background-image: url(./img/bk.png);
    }
    .info1 {
      color: rgb(241, 177, 117);
    }
    .data {
      display: flex;
      justify-content: flex-start;
      max-width: 590px;
      padding-bottom: 5px;
      width: 100%;
      font-family: Louis George Cafe;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
      .item {
        margin-top: 15px;
        margin-right: 40px;
      }
      span {
        margin-top: 10px;
        font-size: 20px;
        line-height: 140%;
        color: #085da3;
      }
    }
    .qrcode {
      .flex {
        display: flex;
      }
      .tit {
        font-size: 26px;
        padding-bottom: 10px;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        color: #085da3;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        .sp {
          padding: 6px 12px 2px 12px;
          font-size: 12px;
          cursor: pointer;
          &.active {
            background: #2f98ee;
            border-radius: 4px;
            color: #fff;
          }
        }
      }
      max-width: 450px;
      width: 100%;
      box-shadow: 0px 6px 20px rgba(20, 81, 138, 0.1);
      border-radius: 2px;
      overflow: hidden;
      height: 330px;
      position: absolute;
      background-color: #fff;
      padding: 20px;
      right: 30px;
      top: 5px;
    }
    .panel {
      background: linear-gradient(0deg, #1c70bd, #1c70bd);
      height: 293px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 1110px) {
  .bridge {
    padding-top: 300px;
    .panel {
      .scroll {
        max-width: 100%;
        padding-right: 20px;
      }
    }
    .body {
      .qrcode {
        position: relative;
        margin: 20px auto;
        right: 0;
      }
      .info {
        .data {
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (max-width: 486px) {
  .bridge {
    padding-top: 400px;
    .body {
      .data {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
      }
      .qrcode {
        padding: 10px;
      }
    }
  }
}
</style>